import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledContainer = styled(Container)`
  max-width: 1100px;
`

const StyledProductDetails = styled.section`
  padding: 90px 0 60px;
  text-align: center;
  background-color: ${colors.grayLight};

  ${breakpoint.small`
	padding: 120px 0 20px;
  `}

  ${breakpoint.medium`
    padding: 140px 0 50px;
  `}

    h2 {
      padding-bottom: 3rem;

		${breakpoint.medium`
			padding-bottom: 5rem;
		`} d
    }

    p {
      line-height: 1.6em;
    }
  

  .product-details__features {
    position: relative;
    padding: 60px 0;

    ${breakpoint.medium`
      padding: 132px 0;
    `}
  }

  
.tab { 
	font-size: 16px;
	display: flex;
	column-count: 2;
	justify-content: center;
  	text-align: center;

     ${breakpoint.medium`
		font-size: 22px;
		margin: 0 2px;
    `}
 }
  
  .tablinks {
  	text-align: center;
  	color: ${colors.charcoal}; 
  	cursor: pointer;
	padding-bottom: 20px;
	width: 100%;
	border-bottom: 6px solid ${colors.grayLight};

		&:hover {
			color: ${colors.purpleDark}; 
		}

		&.active, &:focus {
			color: ${colors.purpleDark}; 
			border-bottom: 6px solid ${colors.purpleDark};
		}
  	  }

	
	.feature {
		border-top: 1px solid ${colors.charcoal};
		padding-top: 40px;
		display: none;
	}
	
	.visible { display: block; }

	.citation { 
		display: none;
		font-size: 14px; 
		color: ${colors.gray};
		text-align: center;
		margin-top: 3rem;

		${breakpoint.medium`
		  text-align: left;
		`}
	}

	.styledFeature, .grid-styledFeature {
		align-content: start;
		justify-content: center;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin: 0 auto;
		z-index: 1;
		text-align: center;
		min-height: 300px;

	  ${breakpoint.small`
	  justify-content: start;
		flex-wrap: no-wrap;
		text-align: left;
		padding: 0 20px;
	  `}

	.feature__image {
		width: 100%; 
		margin: 0 auto;
		padding: 100px;

		${breakpoint.small`
		  	margin: 0;
		  	padding: 0;
		`}
	}
	
	.details__content {
		display: flex;
		justify-content: center;
		align-content: center;
		width: 100%;
		column-gap: 5%;
		flex-direction: column;
		
		${breakpoint.small`
			column-gap: 20%;
			flex-direction: row;
		`}
	}
	
	.details__image {
		width: 100%;
		max-width: 250px; 
		margin: 0 auto;
		padding-bottom: 10px;

		${breakpoint.small`
			max-width: 300px; 
			width: 100%;
		  	margin: 0;
		`}
		
		img {
			object-fit: contain!important;
		}
	}

	.profile {
		max-width: 180px!important; 

		${breakpoint.small`
			max-width: 230px!important; 
		`}

	}


	  .feature__content {
		width: 100%;
		margin: 20px auto 40px;

		${breakpoint.small`
		  width: 50%;
		`}

		${breakpoint.medium`
		  width: 60%;
		`}
	
			a {
			color: ${colors.purpleDark};
		
			&:hover {
				color: ${colors.orange};
			}
		 }
	  }
	  
	  .feature__content-grid {
		width: 100%;

		${breakpoint.small`
		  width: 70%;
		  margin: 0 40px 20px;
		`}
	  
	  }
  
		 
		.grid-container {
			display: grid;
			grid-template-columns: auto auto auto;
			line-height: 1.2em;

			${breakpoint.small`
				padding: 10px;
			`}

		}

		.grid-container > div {
			border: ${colors.gray} 1px solid;
			text-align: center;
			padding: 10px 0;
		}

		.grid-title {
			font-weight: 600;
		}

		.sub {
			font-style: italic;
			line-height: 1.1em;
			margin: 10px auto;
			text-align: center;
			font-size: x-small;
			color: ${colors.graySelect};

			${breakpoint.medium`
			  font-size: small;
			`}
		}


	}
	
		.grid-styledFeature {
		  	justify-content: center;
		 }

`

const ProductDetails = props => {

	return (
		<StyledProductDetails>
			<StyledContainer data-aos="fade-up" data-aos-once="false">
			{ props.titleText ? ( <h2>{props.titleText}</h2> ) : <h2>Product details</h2> }
				<div className="tab">
					<button
						type="button"
						data-filter
						data-filter-type="feature"
						data-target="about"
						className="tablinks active"
						onClick={() => props.filterFunction("feature", "about")}
						data-aos="fade-up"
						data-aos-once="false"
					>
						About
					</button>
					<button
						type="button"
						data-filter
						data-filter-type="feature"
						data-target="size"
						className="tablinks"
						onClick={() => props.filterFunction("feature", "size")}
						data-aos="fade-up"
						data-aos-once="false"
					>
						Size
					</button>
					<button
						type="button"
						data-filter
						data-filter-type="feature"
						data-target="performance"
						className="tablinks"
						onClick={() => props.filterFunction("feature", "performance")}
						data-aos="fade-up"
						data-aos-once="false"
					>
						Performance
					</button>
				</div>

				<div className="feature visible" data-filter-type="feature" data-filter="about" data-aos="fade-up" data-aos-once="false">
					<div className="styledFeature">
						<StaticImage
							src="../../../assets/images/sexual-health-test/sti.png"
							width={300}
							layout="fixed"
							alt="Visby Medical Sexual Health Test"
							className="feature__image"
						/>
						<div className="feature__content">
							<p>The Visby Medical Sexual Health Test detects three of the most common and curable STIs: <i>Chlamydia</i>, <i>Gonorrhea</i>, <i>Trichomoniasis</i> — in under
								30 minutes — so you can provide data-driven treatment in the same visit.</p>
						</div>
					</div>
				</div>

				<div className="feature" data-filter-type="feature" data-filter="size">
					<div className="styledFeature">
						<div className="details__content">
							<StaticImage
								src="../../../assets/images/sexual-health-test/details/device-face-new.png"
								width={300}
								placeholder="tracedSVG"
								alt="Visby Medical Sexual Health Test face measurements"
								className="details__image"
							/>
							<StaticImage
								src="../../../assets/images/sexual-health-test/details/device-profile.png"
								width={300}
								placeholder="tracedSVG"
								alt="Visby Medical Sexual Health Test profile measurements"
								className="details__image profile"
							/>
						</div>
					</div>
				</div>

				<div className="feature" data-filter-type="feature" data-filter="performance">
					<div className="grid-styledFeature">
						<div className="feature__content-grid">
							<div className="grid-container">
								<div></div>
								<div>Positive Percent<br />Agreement (PPA)</div>
								<div>Negative Percent<br />Agreement (NPA)</div>
								<div className="grid-title">CT</div>
								<div>97.4%</div>
								<div>97.8%</div>
								<div className="grid-title">NG</div>
								<div>97.8%</div>
								<div>99.1%</div>
								<div className="grid-title">TV</div>
								<div>99.3%</div>
								<div>96.7%</div>
							</div>
							<p className="sub">*See Instructions for Use.</p>
						</div>
					</div>
				</div>
			</StyledContainer>
		</StyledProductDetails>
	)
}

class Final extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: "about"
		}
	}

	componentDidMount() {
		var filtby = this.state.filter;
		if (this.props.filterby)
			filtby = this.props.filterby;

		this.handleFilterChange("features", filtby, null);
	}

	handleFilterChange = (type, value, event) => {
		const inactiveFilters = document.querySelectorAll("[data-filter]")
		inactiveFilters.forEach(filter => {
			filter.classList.remove("active")
		})

		const activeFilter = document.querySelector(
			"[data-filter][data-target='" + value + "']"
		)
		activeFilter.classList.add("active")


		const previousElements = document.querySelectorAll(
			"[data-filter-type='" + type + "']"
		)
		previousElements.forEach(post => {
			post.classList.remove("visible")
		})

		const elementsToFilter = document.querySelectorAll(
			"[data-filter-type='" + type + "'][data-filter='" + value + "']"
		)
		elementsToFilter.forEach(post => {
			post.classList.add("visible")
		})


		const changeHero = document.querySelectorAll('.hover--image')
		changeHero.forEach(filter => {
			filter.classList.toggle("visible")
		})


	}


	handleToggleChange = (type, value, event) => {

		const activeFilters = document.querySelectorAll(
			"[data-filter][data-target='" + value + "']"
		)
		activeFilters.forEach(filter => {
			filter.classList.toggle("active")
		})
	}


	render = props => (
		<React.Fragment>
			<ProductDetails titleText={this.props.titleText} filterFunction={this.handleFilterChange} toggleFunction={this.handleToggleChange} />
		</React.Fragment>
	)
}

export default Final
