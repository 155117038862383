import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledContainer = styled(Container)`
  max-width: 1200px;
`

const StyledHowToUse = styled.section`
  position: relative;
  padding: 50px 0;
  border-bottom-width: 0;

  ${breakpoint.medium`
    padding: 60px 0 180px;
  `}

  .quick-results__heading {
    max-width: 827px;
    margin: 0 auto;
    text-align: center;

    ${breakpoint.medium`
      margin-bottom: 40px;
    `}

    h2 {
      padding-bottom: 0.5rem;
    }
  
  }
`

const StyledVideo = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 56%;
  background-color: ${colors.grayLight};

    &::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 15px;
		left: 15px;
		background-image: radial-gradient(${colors.grayMedium} 0.05em,transparent 0.08em),radial-gradient(${colors.grayMedium} 0.05em,transparent 0.08em);
		background-size: 12px 12px !important;
		background-position: 0 0, 18px 18px;
		background-repeat: repeat;
		opacity: 0.3;
		z-index: -1;


      ${breakpoint.medium`
        top: 40px;
        left: 40px;
      `}
    }


  .video__placeholder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${props => (props.showPlaceholder ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 500;

    &:hover {

      svg {
        fill: ${colors.orange};
      }
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 499;
  }
`

const Video = props => {
  return (
    <StyledVideo
      onClick={props.onClick}
      showPlaceholder={props.showPlaceholder}
      onKeyDown={props.onClick}
      tabIndex="0"
      role="none"
    >
        <StaticImage
          src="../../../assets/images/sexual-health-test/video-placeholder.jpg"
          width={1260}
          alt="How to use the Visby Medical test"
          className="video__placeholder"
          style={{
            position: "absolute",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      <iframe
        title="How to use the Visby Medical test"
        src={
          props.showPlaceholder
            ? "https://player.vimeo.com/video/799359707?h=d12f521226"
            : "https://player.vimeo.com/video/799359707?h=d12f521226&autoplay=1"
        }
        frameBorder="0"
        allow="autoplay; fullscreen"
      ></iframe>
    </StyledVideo>
  )
}

class HowToUse extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPlaceholder: true,
    }
  }

  hidePlaceholder = () => {
    if (this.state.showPlaceholder) {
      this.setState({
        showPlaceholder: false,
      })
    }
  }

  render = () => (
    <StyledHowToUse>
      <StyledContainer>
        <div className="quick-results__heading" id="visby-personal-pcr">
          <h2 data-aos="fade-up" data-aos-once="false">How it works - Sexual&nbsp;Health&nbsp;Test</h2>
          <p data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
            Less than 15 seconds of hands-on time. Results in under 30 minutes.
          </p>
        </div>

        <div data-aos="fade-up" data-aos-once="false" data-aos-delay="600">
          <Video
          showPlaceholder={this.state.showPlaceholder}
          onClick={this.hidePlaceholder}
          />
        </div>
      </StyledContainer>
    </StyledHowToUse>
  )
}

export default HowToUse
