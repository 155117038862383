import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import OrderForm from "./screens/sexual-health-test-pulsepoint/order-form"
import HowToUse from "./screens/sexual-health-test-pulsepoint/how-to-use"
import ProductDetails from "./screens/sexual-health-test/product-details"
const FORM_ID = process.env.GATSBY_FORM_STI_TEST_LI

const SexualHealthTest = () => {
  return (
    <React.Fragment>
      <OrderForm
        wordpressFormID={FORM_ID}
        thankYouPage="sexual-health-test-li-thank-you"
      />
      <HowToUse />
      <ProductDetails />
    </React.Fragment>
  )
}
export default SexualHealthTest

export const Head = () => (
  <Seo
    title="Sexual Health Test — POC rapid PCR device"
    description="Visby Medical's PCR Sexual Health Test detects chlamydia, gonorrhea, and trichomoniasis in under 30 minutes."
    image="/meta/sexual-health-test.jpg"
  />
)
